import { Component, OnInit } from '@angular/core';
import { SlipHistoryService } from 'src/app/shared/service/slipHistory.service';
declare var jquery: any;
declare var $: any;

@Component({
  selector: 'app-slipHistory',
  templateUrl: './slipHistory.component.html',
  styleUrls: ['./slipHistory.component.css']
})
export class SlipHistoryComponent implements OnInit {

  emp;
  slipList = [];

  constructor(
    private slipHistoryService: SlipHistoryService
  ) { }


  ngOnInit() {
    this.emp = JSON.parse(localStorage.getItem('tisToken'));
    this.getSlipHistory(this.emp.id);
  } 

  getSlipHistory(employee_id){
    this.slipHistoryService.search({employee_id: employee_id}).subscribe(res=>{
      console.log(res);
      this.slipList = res.data;
    });
  }

  downloadSlip(item: any){
    window.open(item.url);
  }

  displaySlipTag(item: any){
    let result;
    if(item.tag === 'W'){
      switch (item.tag_detail) {
        case '2024_1-0':
          result = '1-15 มกราคม 2567';
          break;
        case '2024_1-1':
          result = '16-31 มกราคม 2567';
          break;
        case '2024_2-0':
          result = '1-15 กุมภาพันธ์ 2567';
          break;
        case '2024_2-1':
          result = '16-28 กุมภาพันธ์ 2567';
          break;
        case '2024_3-0':
          result = '1-15 มีนาคม 2567';
          break;
        case '2024_3-1':
          result = '16-31 มีนาคม 2567';
          break;
        case '2024_4-0':
          result = '1-15 เมษายน 2567';
          break;
        case '2024_4-1':
          result = '16-30 เมษายน 2567';
          break;
        case '2024_5-0':
          result = '1-15 พฤษภาคม 2567';
          break;
        case '2024_5-1':
          result = '16-31 พฤษภาคม 2567';
          break;
        case '2024_6-0':
          result = '1-15 มิถุนายน 2567';
          break;
        case '2024_6-1':
          result = '16-30 มิถุนายน 2567';
          break;
        case '2024_7-0':
          result = '1-15 กรกฎาคม 2567';
          break;
        case '2024_7-1':
          result = '16-31 กรกฎาคม 2567';
          break;
        case '2024_8-0':
          result = '1-15 สิงหาคม 2567';
          break;
        case '2024_8-1':
          result = '16-31 สิงหาคม 2567';
          break;
        case '2024_9-0':
          result = '1-15 กันยายน 2567';
          break;
        case '2024_9-1':
          result = '16-30 กันยายน 2567';
          break;
        case '2024_10-0':
          result = '1-15 ตุลาคม 2567';
          break;
        case '2024_10-1':
          result = '16-31 ตุลาคม 2567';
          break;
        case '2024_11-0':
          result = '1-15 พฤศจิกายน 2567';
          break;
        case '2024_11-1':
          result = '16-30 พฤศจิกายน 2567';
          break;
        case '2024_12-0':
          result = '1-15 ธันวาคม 2567';
          break;
        case '2024_12-1':
          result = '16-31 ธันวาคม 2567';
          break;
        default:
          result = 'ไม่พบข้อมูล'; // Fallback for unrecognized input
      } 
    }else if(item.tag === 'Y'){
      let splitTagDetail = item.tag_detail.split('-');
      result = this.displayMonthName(Number(splitTagDetail[0]))+ ' ' + (Number(splitTagDetail[1])+543);
    }
    return result;
  }

  displayMonthName(item: number): string {
    let monthName: string = '';
  
    switch (item) {
      case 1:
        monthName = 'มกราคม';
        break;
      case 2:
        monthName = 'กุมภาพันธ์';
        break;
      case 3:
        monthName = 'มีนาคม';
        break;
      case 4:
        monthName = 'เมษายน';
        break;
      case 5:
        monthName = 'พฤษภาคม';
        break;
      case 6:
        monthName = 'มิถุนายน';
        break;
      case 7:
        monthName = 'กรกฎาคม';
        break;
      case 8:
        monthName = 'สิงหาคม';
        break;
      case 9:
        monthName = 'กันยายน';
        break;
      case 10:
        monthName = 'ตุลาคม';
        break;
      case 11:
        monthName = 'พฤศจิกายน';
        break;
      case 12:
        monthName = 'ธันวาคม';
        break;
      default:
        monthName = 'ไม่พบข้อมูล'; // Fallback for invalid item
    }
  
    return monthName;
  }
  

}
