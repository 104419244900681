import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { CompanyComponent } from './setting/company/company.component';
import { DepartmentComponent } from './setting/department/department.component';
import { PositionComponent } from './setting/position/position.component';
import { MasterDataComponent } from './setting/masterData/masterData.component';
import { ProductCategoryComponent } from './stock/productCategory/productCategory.component';
import { StockComponent } from './stock/stock.component';
import { EmployeeComponent } from './employee/employee.component';
import { EmployeeAddComponent } from './employee/add/employee_add.component';
import { EmployeeViewComponent } from './employee/view/employee_view.component';
import { EmployeeEditComponent } from './employee/edit/employee_edit.component';
import { CustomerComponent } from './customer/customer.component';
import { CustomerAddComponent } from './customer/add/customer_add.component';
import { CustomerViewComponent } from './customer/view/customer_view.component';
import { CustomerEditComponent } from './customer/edit/customer_edit.component';
import { PasttimeCheckpointComponent } from './guard/pasttime/checkpoint/pasttimeCheckpoint.component';
import { ReportPasttimeCheckpointComponent } from './report/guard/pasttime/checkpoint/reportPasttimeCheckpoint.component';
import { PasttimePaymentComponent } from './guard/pasttime/payment/pasttimePayment.component';
import { WorkScheduleComponent } from './guard/permanent/workSchedule/workSchedule.component';
import { ReportSlipComponent } from './report/slip/reportSlip.component';
import { CheckTransferPayComponent } from './checktransferpay/checktransferpay.component';
import { CalendarHolidayComponent } from './setting/calendar/calendar.component';
import { OutsiderComponent } from './outsider/outsider.component';
import { ShareComponent } from './share/share.component';
import { GuardCheckPointComponent } from './guard/permanent/guardCheckPoint/guardCheckPoint.component';
import { ComparisonComponent } from './report/comparison/comparison.component';
import { ComparisonRecheckComponent } from './report/comparisonRecheck/comparisonRecheck.component';
import { ComparisonAuditComponent } from './report/comparisonAudit/comparisonAudit.component';
import { ComplaintComponent } from './complaint/complaint.component';
import { InspectionComponent } from './setting/inspection/inspection.component';
import { CustomerAuditComponent } from './setting/customerAudit/customerAudit.component';
import { CustomerAuditManageComponent } from './setting/customerAudit/customerAuditMange/customerAuditManage.component';
import { RentHouseComponent } from './setting/rentHouse/rentHouse.component';
import { InsuranceComponent } from './setting/insurance/insurance.component';
import { PickStockComponent } from './stock/pickStock/pickStock.component';
import { RentHouseManageComponent } from './setting/rentHouse/rentHouseManange/rentHouseManage.component';
import { DocumentComponent } from './document/document.component';
import { ContractAuditComponent } from './setting/contractAudit/contract-audit.component';
import { SickLeaveComponent } from './new-guard/sickLeave/sickLeave.component';
import { PersonalLeaveComponent } from './new-guard/personalLeave/personalLeave.component';
import { AnnualLeaveComponent } from './new-guard/annualLeave/annualLeave.component';
import { WorkingComponent } from './new-guard/working/working.component';
import { WarantyInsuranceComponent } from './new-guard/warantyInsurance/warantyInsurance.component';
import { ReserveLoanComponent } from './new-guard/reserveLoan/reserveLoan.component';
import { CheckinComponent } from './new-guard/checkin/checkin.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { LeaveComponent } from './new-guard/leave/leave.component';
import { SlipHistoryComponent } from './new-guard/slipHistory/slipHistory.component';

const routes: Routes = [
  {
    path: '',
    component: DashboardComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/company',
    component: CompanyComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/department',
    component: DepartmentComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/position',
    component: PositionComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/master-data',
    component: MasterDataComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/calendar/holiday',
    component: CalendarHolidayComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/inspection',
    component: InspectionComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/customer-audit',
    component: CustomerAuditComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/customer-audit/manage/:id',
    component: CustomerAuditManageComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/rent-house',
    component: RentHouseComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/rent-house/manage/:id',
    component: RentHouseManageComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/insurance',
    component: InsuranceComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'stock/category',
    component: ProductCategoryComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'stock',
    component: StockComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'stock/pick-stock',
    component: PickStockComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'employee',
    component: EmployeeComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'employee/add',
    component: EmployeeAddComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'employee/view/:id',
    component: EmployeeViewComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'employee/edit/:id',
    component: EmployeeEditComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'customer',
    component: CustomerComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'customer/add',
    component: CustomerAddComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'customer/:id/:display',
    component: CustomerAddComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/pasttime/checkpoint',
    component: PasttimeCheckpointComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'report/guard/pasttime/checkpoint',
    component: ReportPasttimeCheckpointComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/pasttime/payment',
    component: PasttimePaymentComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/permanent/shcedule',
    component: WorkScheduleComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/permanent/checkPoint',
    component: GuardCheckPointComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'report/slip',
    component: ReportSlipComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'report/comparison',
    component: ComparisonComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'report/comparisonAudit',
    component: ComparisonAuditComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'report/comparisonRecheck',
    component: ComparisonRecheckComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'check-transfer-pay',
    component: CheckTransferPayComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'outsider',
    component: OutsiderComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'share',
    component: ShareComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'complaint',
    component: ComplaintComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'document',
    component: DocumentComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'setting/contract-audit',
    component: ContractAuditComponent
    //,canActivate: [NeedAuthGuard]
  },

  //new guard
  {
    path: 'guard/sickleave',
    component: SickLeaveComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/personalleave',
    component: PersonalLeaveComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/annualleave',
    component: AnnualLeaveComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/working',
    component: WorkingComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/waranty-insurance',
    component: WarantyInsuranceComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/reserveloan',
    component: ReserveLoanComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/checkin',
    component: CheckinComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/leave',
    component: LeaveComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'reset-password',
    component: ResetPasswordComponent
    //,canActivate: [NeedAuthGuard]
  },
  {
    path: 'guard/slip-history',
    component: SlipHistoryComponent
    //,canActivate: [NeedAuthGuard]
  },
  

];

@NgModule({
  imports: [RouterModule.forRoot(routes, {useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
